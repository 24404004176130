var getInputNumbersValue = function (input) {
    // Return stripped input value — just numbers
    return input.value.replace(/\D/g, '');
}

export var onPhonePaste = function (e) {
    var input = e.target,
        inputNumbersValue = getInputNumbersValue(input);
    var pasted = e.clipboardData || window.clipboardData;
    if (pasted) {
        var pastedText = pasted.getData('Text');
        if (/\D/g.test(pastedText)) {
            // Attempt to paste non-numeric symbol — remove all non-numeric symbols,
            // formatting will be in onPhoneInput handler
            input.value = inputNumbersValue;
        }
        return input.value
    }
}

export var onPhoneInput = function (e) {
    var input = e.target,
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = "";

    if (!inputNumbersValue) {
        input.value = (e.nativeEvent.data == "+") ? "+" : "";
        return input.value
    }

    if (input.value.length != selectionStart) {
        if (e.nativeEvent.data && /\D/g.test(e.nativeEvent.data)) {
            // Attempt to input non-numeric symbol
            input.value = inputNumbersValue;
        }
        return input.value;
    }

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] == "9") inputNumbersValue = "7" + inputNumbersValue;
        var firstSymbols = (inputNumbersValue[0] == "8") ? "8" : "+7";
        formattedInputValue = input.value = firstSymbols + " ";
        if (inputNumbersValue.length > 1) {
            formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
        }
        if (inputNumbersValue.length >= 5) {
            formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
            formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
            formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
        }
    } else {
        formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
    return input.value
}

export var onPhoneKeyDown = function (e) {
    var inputValue = e.target.value.replace(/\D/g, '');
    if (e.key == "Backspace" && inputValue.length == 1) {
        // Clear input after remove last symbol
        e.target.value = "";
        return e.target.value
    } else if (["Backspace", "Delete"].indexOf(e.key) > -1 && inputValue.length > 1) {
        // Prevent when removing service symbols
        var symToClear
        switch (e.key) {
            case "Backspace": // BackSpace key
                symToClear = e.target.value[e.target.selectionStart - 1];
                break;
            case "Delete": // Delete key
                symToClear = e.target.value[e.target.selectionStart];
                break;
        }
        if (symToClear && /\D/.test(symToClear)) e.preventDefault();
    }
}
