import React from 'react'
import styles from './Contacts.module.css'
import cond_img from '../../assets/img/conditionSmall.png'
import Content from '../Content/Content';
import CarCategoryTitle from '../../components/CarCategoryTiitle/CarCategoryTitle';
import ContactButton from '../../components/ContactButton/ContactButton';


const Contacts = () => {
    return (
        <Content is_full={true}>
            <div className={styles.container}>
                <div className={styles.category}>
                    <CarCategoryTitle title="Контакты" margin={0}/>
                </div>
                <div className={styles.contact_container}>
                    <div className={styles.address}>
                        <span id={styles.up}>1-ая Новопроточная, 1</span>
                        <span id={styles.down}>Центральный район, Чита, 672007</span>
                    </div>
                    <div className={styles.phone_info}> 
                        <div className={styles.phones}>
                            <span>+7 (924) 570-40-20</span>
                            <span id={styles.snd_num}>+7 (3022) 71-40-20</span>
                        </div>
                        <div className={styles.btn}>
                            <ContactButton/>
                        </div>
                    </div>
                </div>
                <div className={styles.map}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2454.2762040726484!2d113.48005380079981!3d52.038282254929946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5dca354078c5526d%3A0xe4b119c12108e856!2z0YPQuy4g0J3QvtCy0L7Qv9GA0L7RgtC-0YfQvdCw0Y8sIDEsINCn0LjRgtCwLCDQl9Cw0LHQsNC50LrQsNC70YzRgdC60LjQuSDQutGA0LDQuSwgNjcyMDAw!5e0!3m2!1sru!2sru!4v1546689198951"
                        // width="600"
                        // height="450"
                        frameborder="0"
                        style={{border:0}} allowfullscreen></iframe>
                </div>
            </div>
        </Content>
    );
};

export default Contacts;