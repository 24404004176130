import React, { useEffect, useState } from 'react'
import axios from 'axios'
import CarCard from '../../components/Car/Car';
import styles from './Cars.module.css'
import CarCategoryTitle from '../../components/CarCategoryTiitle/CarCategoryTitle';
import Content from '../Content/Content';



const Cars = ({data}) => {
    const cars = data;

    return (
        <Content>
            <div className={styles.category_container}>
                {Object.entries(cars).map((obj, idx) => {
                    let [category, cars_obj] = obj
                    return !!cars_obj.length && (
                        <React.Fragment key={idx}>
                            <CarCategoryTitle title={category}/>
                            <div className={styles.car_container}>
                                {cars_obj.map((car, inner_idx) => {
                                    return <CarCard car_obj={car} key={inner_idx}/>
                                })}
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        </Content>
    );
};

export default Cars;