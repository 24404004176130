import React from 'react'
import styles from './Docs.module.css'
import pass_img from '../../assets/img/pasport.png'
import license_img from '../../assets/img/license.png'
import btn_img from '../../assets/img/btn.png'
import Content from '../Content/Content';
import CarCategoryTitle from '../../components/CarCategoryTiitle/CarCategoryTitle';


const Docs = () => {
    const phys = [
        [
            "Паспорт гражданина Российской Федерации - для граждан РФ. Национальный паспорт с переводом на русский язык, заверенным торговой палатой РФ - для иностранных граждан.",
            pass_img
        ],
        [
            "Водительское удостоверение Российской Федерации - для граждан РФ. Национальное водительское удостоверение с переводом на русский язык, заверенным торговой палатой РФ либо международное водительское удостоверение с информацией на русском языке - для иностранных граждан",
            license_img
        ],
    ]
    const urs = [
        "Карточка предприятия с основной информацией, реквизитами и фактическим адресом.",
        "Копия решения участников/протокол об избрании (назначении) руководителя.",
        "Паспорт гражданина Российской Федерации.",
        "Копия свидетельства о регистрации.",
        "Копия приказа о вступлении в должность руководителя.",
        "Водительское удостоверение Российской Федерации.",
        "Доверенность от организации на право подписания договоров аренды и актов приема-передачи автомобиля, заверенная печатью предприятия и подписью руководителя."
    ]
    return (
        <Content is_full={false}>
            <div className={styles.category}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <CarCategoryTitle title="Документы" margin={0}/>
                    </div>
                    <div className={styles.subtitle}>
                        <h3>Физические лица</h3>
                    </div>
                    {
                        phys.map((cond, idx) => {
                            return (
                                <div key={idx} className={styles.condition}>
                                    <img src={cond[1]}/>
                                    <span>{cond[0]}</span>
                                </div>)
                        })
                    }
                    <div className={styles.subtitle}>
                        <h3>Юридические лица</h3>
                    </div>
                    {
                        urs.map((cond, idx) => {
                            return (
                                <div key={idx} className={styles.condition}>
                                    <img src={btn_img}/>
                                    <span>{cond}</span>
                                </div>)
                        })
                    }
                </div>
            </div>
        </Content>
    );
};

export default Docs;