import React from 'react'
import styles from './CallbackForm.module.css'


const CallbackForm = ({
    onChangeName,
    onChangePhone,
    onClickBox,
    onPhoneNumberKeyDown,
    onPhoneNumberPaste}) => {
    return (
        <div className={styles.container}>
            <form id={styles.feedbackForm}>
                <input onChange={onChangeName} type="text" name="user" id={styles.userForm} placeholder="Имя*"/>
                <input
                    onChange={onChangePhone}
                    onPaste={onPhoneNumberPaste}
                    onKeyDown={onPhoneNumberKeyDown}
                    type="tel"
                    name="number"
                    id={styles.numberForm}
                    placeholder="Телефон*"/>
            </form>
            <div className={styles.privacy}>
                <input type="checkbox" onClick={onClickBox}/>
                <span>Я соглашаюсь с политикой <a href="static/privacy.pdf" target="_blank">конфиденциальности</a></span>
            </div>
        </div>
    );
};

export default CallbackForm;