import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {Switch, Route, Redirect} from 'react-router-dom'
import { publicRoutes } from '../routes';

const AppRouter = () => {

    const [cars, setCars] = useState([])

    useEffect(() => {
        const url = "/api/cars/by-categories"
        axios.get(url)
        .then((response) => {
            setCars(response.data)
        })
    }, []);

    return (
        <Switch>
            {publicRoutes.map(({path, Component}) => 
                    <Route key={path} path={path} render={(props) => <Component {...props} data={cars}/>} exact/>
            )}
             <Redirect to={"/"}/>
        </Switch>
    );
};

export default AppRouter;