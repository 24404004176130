import React from 'react'
import styles from './Conditions.module.css'
import cond_img from '../../assets/img/conditionSmall.png'
import Content from '../Content/Content';
import CarCategoryTitle from '../../components/CarCategoryTiitle/CarCategoryTitle';


const Conditions = () => {
    const conditions_list = [
        "Ваш возраст более 23 лет.",
        "Вы не имеете психических и физических заболеваний, являющихся препятствием для управления транспортным средством.",
        "Ранее вам не отказывали в осуществлении автомобильного страхования.",
        "Вас никогда не отстраняли от управления автомобилем по причине употребления алкоголя, спиртосодержащей продукции, наркотических средств или психотропных веществ.",
        "Автомобиль предоставляется чистый, с полным баком и должен быть возвращен в таком же виде.",
        "В ваш адрес никогда не выносилось судебное решение о лишении водительского удостоверения.",
        "Ваш водительский стаж более 3 лет.",
        "У вас есть действующая прописка на территории РФ – для граждан РФ.",
        "Минимальный срок аренды не менее 24 часов.",
        "Территория эксплуатации: Забайкальский край и ближайшие регионы."
    ]
    return (
        <Content is_full={true}>
            <div className={styles.category}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <CarCategoryTitle title="Условия аренды" margin={0}/>
                    </div>
                    {
                        conditions_list.map((cond, idx) => {
                            return (
                                <div key={idx} className={styles.condition}>
                                    <img src={cond_img}/>
                                    <span>{cond}</span>
                                </div>)
                        })
                    }
                </div>
            </div>
        </Content>
    );
};

export default Conditions;