import Header from "./components/Header/Header";
import MainPage from "./pages/MainPage/MainPage";
import Cars from "./pages/Cars/Cars";
import Conditions from "./pages/Conditions/Conditions";
import Docs from "./pages/Docs/Docs";
import Contacts from "./pages/Contacts/Contacts";

export const publicRoutes = [
    {
        path: '/cars/:id',
        Component: Header
    },
    {
        path: '/cars',
        Component: Cars
    },
    {
        path: '/conditions',
        Component: Conditions
    },
    {
        path: '/docs',
        Component: Docs
    },
    {
        path: '/contacts',
        Component: Contacts
    },
    {
        path: '/',
        Component: Cars
    }
]