import React from 'react'
import styles from './CarCategoryTitle.module.css'


const CarCategoryTitle = (props) => {
    const margin_class = props.margin === undefined ? null: styles.margin_class
    return (
        <div className={`${styles.container} ${margin_class}`}>
            <h2 className={styles.title}>{props.title}</h2>
        </div>
    );
};

export default CarCategoryTitle;