import React, { useState } from 'react'
import Modal from '../Modal/Modal'
import styles from './ContactButton.module.css'
import CallbackForm from '../CallbackForm/CallbackForm';
import p_image from '../../assets/img/phone.svg'
import axios from 'axios'
import { onPhoneInput, onPhonePaste, onPhoneKeyDown } from '../../utils/phone_mask';


function getCookie(name) {
    let match = document.cookie
       .split('; ')
       .find(row => row.startsWith(`${name}=`));
    
    return match ? match.split('=')[1] : undefined;
}


const FixedButton = ({
    onClick,
    visible,
    title,
    content,
    footer,
    onClose
}) => {
    return (
        <div onClick={onClick} className={styles.f_container}>
            <svg className={styles.obj} version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	            width="512.001px" height="512.001px" viewBox="0 0 512.001 512.001">
                <path d="M462.491,468.206l-33.938,33.937c-6.062,6.031-23.812,9.844-24.343,9.844c-107.435,0.905-210.869-41.279-286.882-117.31
		            C41.097,318.46-1.136,214.619,0.036,106.872c0-0.063,3.891-17.312,9.938-23.312l33.937-33.968
		            c12.453-12.437,36.295-18.062,52.998-12.5l7.156,2.406c16.703,5.562,34.155,23.999,38.78,40.967l17.093,62.717
		            c4.64,17-1.594,41.186-14.031,53.623l-22.687,22.687c22.25,82.467,86.919,147.121,169.339,169.402l22.687-22.688
		            c12.438-12.438,36.687-18.656,53.687-14.031l62.718,17.125c16.937,4.594,35.374,22.03,40.968,38.748l2.375,7.156
		            C480.552,431.926,474.928,455.769,462.491,468.206z M287.996,255.993h31.999c0-35.343-28.655-63.998-63.998-63.998v31.999
		            C273.636,223.994,287.996,238.368,287.996,255.993z M415.992,255.993c0-88.373-71.623-159.996-159.995-159.996v32
		            c70.591,0,127.996,57.436,127.996,127.996H415.992z M255.997,0v31.999c123.496,0,223.993,100.497,223.993,223.994h31.999
		            C511.989,114.622,397.368,0,255.997,0z"></path>
            </svg>
            <Modal
                visible={visible}
                title={title}
                content={content}
                footer={footer}
                onClose={onClose}
            />
        </div>
    )
}


const ContactButton = ({is_fixed=false}) => {
    const default_title = "Отправьте заявку и мы вам перезвоним"
    const [isModal, setModal] = useState(false)
    const [phone, setPhone] = useState(null)
    const [name, setName] = useState(null)
    const [title, setTitle] = useState(default_title)
    const [is_request, setRequest] = useState(false)
    const [is_complete, setComplete] = useState(false)
    const [checked, setChecked] = useState(false)


    const isDisabled = () => {
        return !name || !phone || !checked || is_request || is_complete 
    }

    const onClickBox = (e) => {
        setChecked(!checked)
    }

    const onPhoneNumberPaste = (e) => {
        let new_phone = onPhonePaste(e);
        setPhone(new_phone);
    }

    const onPhoneNumberKeyDown = (e) => {
        let new_phone = onPhoneKeyDown(e);
        setPhone(new_phone);
    }

    const onChangePhone = (e) => {
        let new_phone = onPhoneInput(e);
        setPhone(new_phone);
    }

    const onChangeName = (e) => {
        setName(e.target.value);
    }
    const onSend = () => {
        setTitle("")
        setRequest(true)
        const url = "/api/clients/"
        axios.defaults.headers.post['X-CSRFToken'] = getCookie("csrftoken")
        axios.post(url, {name: name, phone_number: phone})
        .then(response =>{
            window.ym(83796481,'reachGoal','send_form')
            window.gtag('event', 'send_form', {
                'event_category': 'send',
                'event_label': 'form'
            })
            setRequest(false)
            setComplete(true)
            setPhone(null)
            setName(null)
        })
    }
    const onClose = () => {
        setModal(false)
        setComplete(false)
        setTitle(default_title)
        setRequest(false)
        setChecked(false)
    }
    return (
        is_fixed?
        <FixedButton
            onClick={() => setModal(true)}
            visible={isModal}
            title={title}
            content={
                is_request || is_complete?
                <div>{is_complete? "Отправлено! Мы свяжемся с Вами в ближайшее время!": "Отправляю"}</div>
                : <CallbackForm
                    onChangeName={onChangeName}
                    onChangePhone={onChangePhone}
                    onClickBox={onClickBox}
                    onPhoneNumberKeyDown={onPhoneNumberKeyDown}
                    onPhoneNumberPaste={onPhoneNumberPaste}/>
            }
            footer={(
                <button
                disabled={isDisabled()}
                className={styles.send_btn}
                onClick={onSend}>
                    Отправить
                </button>
            )}
            onClose={onClose}
            />
        :
        <>
        <button onClick={() => setModal(true)} className={styles.draw}><div>Обратный звонок</div></button>
        <Modal
            visible={isModal}
            title={title}
            content={
                is_request || is_complete?
                <div>{is_complete? "Отправлено! Мы свяжемся с Вами в ближайшее время!": "Отправляю"}</div>
                : <CallbackForm
                    onChangeName={onChangeName}
                    onChangePhone={onChangePhone}
                    onClickBox={onClickBox}
                    onPhoneNumberKeyDown={onPhoneNumberKeyDown}
                    onPhoneNumberPaste={onPhoneNumberPaste}/>
            }
            footer={(
                <button
                disabled={isDisabled()}
                className={styles.send_btn}
                onClick={onSend}>
                    Отправить
                </button>
            )}
            onClose={onClose}
            onClickBox = {onClickBox}
        />
        </>
    );
};

export default ContactButton;