import React from 'react'
import { Link } from 'react-router-dom';
import styles from './Car.module.css'


const CarCard = (props) => {
    const characteristics = {
        "Объем двигателя": props.car_obj.volume,
        "Привод": props.car_obj.unit,
        "Руль": props.car_obj.wheel,
        "Мест": props.car_obj.places
    }
    const prices = {
        "1-9 дн.": props.car_obj.max_price,
        "10-19 дн.": props.car_obj.middle_price,
        "20+ дн.": props.car_obj.min_price,
        "Залог": props.car_obj.deposit
    }
    // to={`cars/${props.car_obj.id}`}
    return (
        <Link style={{ textDecoration: 'none' }} to={`/`} className={styles.container}>
            <div className={styles.image_container}>
                <img src={props.car_obj.img} alt={`Аренда ${props.car_obj.mark} ${props.car_obj.model}`}/>
            </div>
            <div className={styles.title}>{props.car_obj.mark} {props.car_obj.model}</div>
            <div className={styles.characteristics_container}>
                <div className={styles.left_side_characteristic}>
                    {Object.entries(characteristics).map((obj) => {
                        let [key, value] = obj
                        return(
                            <div className={styles.characteristic} key={key}>
                                <div className={styles.characteristic_key}>{key}:</div>
                            </div>)
                    })}
                </div>
                <div className={styles.right_side_characteristic}>
                    {Object.entries(characteristics).map((obj) => {
                        let [key, value] = obj
                        return(
                            <div className={styles.characteristic} key={key}>
                                <div className={styles.characteristic_value}>{value}</div>
                            </div>)
                    })}
                </div>
            </div>
            <table className={styles.price_container}>
                <thead>
                    <tr>
                        {Object.entries(prices).map((obj) => {
                            let [key, value] = obj
                            return (
                                <td className={styles.price} key={key}>
                                    <div className={styles.price_key}>{key}</div>
                                </td>)
                        })}
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.price_border}>
                        {Object.entries(prices).map((obj) => {
                                let [key, value] = obj
                                return (
                                    <td className={styles.price} key={key}>
                                        <div className={styles.price_value}>{value}</div>
                                    </td>)
                        })} 
                    </tr>
                </tbody>
            </table>
        </Link>
    );
};

export default CarCard;