import React, {useState} from 'react'
import styles from './Header.module.css'
import logo from '../../assets/img/logo.png'
import { Link } from 'react-router-dom';


const BurgerMenu = ({header, items, active, setActive}) => {
    return (
        <div className={active? `${styles.menu} ${styles.active}`: styles.menu} onClick={() => setActive(false)}>
            <div className={styles.blur}></div>
            <div className={styles.menu_content} onClick={(e) => e.stopPropagation()}>
                <ul>
                    {items.map((item, idx) => {
                        return (<li className={styles.gradient_text} key={idx}>
                            <Link to={item[1]} onClick={() => setActive(false)}>{item[0]}</Link>
                        </li>)
                    })}
                </ul>
            </div>
        </div>
    );
}


const Header = () => {
    // ["Главная", "/"],
    const [burger_active, setBurger] = useState(false)
    const header = ""
    const nav_list = [["Автомобили", "/cars"], ["Условия", "/conditions"],
                      ["Документы", "/docs"], ["Контакты", "/contacts"]]
    return (
        <>
            <BurgerMenu active={burger_active} setActive={setBurger} header={header} items={nav_list}/>
            <nav className={styles.burger}>
                <div
                className={burger_active? `${styles.burger_btn} ${styles.burger_active}`: styles.burger_btn}
                onClick={() => setBurger(!burger_active)}>
                    <span></span>
                </div>
            </nav>
            <div className={styles.container}>
                <div className={styles.img_container}>
                    <img src={logo}></img>
                </div>
                <nav className={styles.nav}>
                    {nav_list.map((nav_list, idx) => {
                        return (
                            <Link to={nav_list[1]} className={styles.gradient_text} key={idx}>
                                {nav_list[0]}
                            </Link>
                        )
                    })}
                </nav>
            </div>
        </>
    );
};

export default Header;