import React from 'react'
import styles from './Content.module.css'
import Header from '../../components/Header/Header';
import ContactButton from '../../components/ContactButton/ContactButton';


const Content = (props) => {
    const full = props.is_full? styles.full: null;
    return (
        <div className={full}>
            <Header/>
            <div className={`${styles.container} ${full}`}>
                {props.children}
            </div>
            <ContactButton is_fixed={true}/>
        </div>
    );
};

export default Content;